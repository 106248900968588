import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../../components/Layout'
import SEO from '../../components/seo'
import RightSide from '../../components/rightside-general'

import BG from '../../images/awards-bg.jpg'

class BusinessAdvisoryServices extends React.Component {
	render() {
		const siteTitle = 'Business Advisory Services'
		const siteDescription = this.props.data.site.siteMetadata.description

		return (
			<Layout location={this.props.location} title={siteTitle}>
				<SEO title={siteTitle} description={siteDescription} />
				<div
					id="sliderhead"
					style={{
						background: `url(${BG}) 50% 13px fixed rgb(34, 34, 34`,
						position: 'relative',
					}}
					className="blog-header2"
				/>
				<div className="container">
					<div className="row">
						<div className="col-md-8 left-list">
							<div id="contentgen">
								<h3 className="mb-3">Business Advisory Services</h3>
								<p>
									WSC Group's Business Advisory Services extends well beyond
									traditional accounting activities. We have access to a wealth
									of knowledge and experience whether you are acquiring, running
									or selling a business.
								</p>
								<p>
									Our aim is to facilitate the achievement of your objectives
									through our range of services, which are carefully designed to
									add value to your business and result in the creation of
									corporate and personal wealth.
								</p>
								<h4>
									Some of the ways in which we help our business clients grow
									are:
								</h4>
								<ul>
									<li>Performing a business diagnosis</li>
									<li>Development of a manageable plan for growth</li>
									<li>Detailed financial analysis</li>
									<li>
										Identification of inefficiencies and formulation of
										strategies to eliminate these inefficiencies
									</li>
									<li>Customer profitability analysis</li>
									<li>Budgeting and forecasting</li>
									<li>Developing strategies to manage change</li>
								</ul>
							</div>
						</div>
						<div className="col-md-4 right-list pull-right">
							<RightSide />
						</div>
					</div>
				</div>
			</Layout>
		)
	}
}

export default BusinessAdvisoryServices

export const BusinessAdvisoryServicesPageQuery = graphql`
	query BusinessAdvisoryServicesPageQuery {
		site {
			siteMetadata {
				title
				author
				description
			}
		}
	}
`
